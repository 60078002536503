.overlayMenu {
  background-color: white;
  position: absolute;
  bottom: 55px;
  border-radius: 8px;
  padding: 4px;
  z-index: 1000;
}

.overlayMenu.right {
  right: 10px;
}

.overlayMenu-list-item {
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
  /* display: grid;
	grid-template-columns: 0.25fr 0.25fr 0.25fr; */
  /* grid- */
  /* display: flex;
	flex-direction: row; */
}

.mapboxgl-ctrl.mapboxgl-ctrl-attrib {
  border-bottom-right-radius: 8px;
}

/* .overlayMenu:hover {
	background-color: #55B467;
} */
.overlayMenu-list-item:first-child:hover,
.overlayMenu-list-item:first-child.selected {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.overlayMenu-list-item:last-child:hover,
.overlayMenu-list-item:last-child.selected {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.mapboxgl-ctrl-group {
  border-radius: 144px;
  margin: auto;
  justify-content: center;
  display: flex;
  box-shadow: none;
}
.mapboxgl-ctrl-group:not(:empty),
.maplibregl-ctrl-group:not(:empty) {
  box-shadow: none;
}

.maplibregl-ctrl button,
.mapboxgl-ctrl button {
  width: 40px;
  height: 40px;
}

.maplibregl-ctrl button:not(:disabled):hover,
.mapboxgl-ctrl button:not(:disabled):hover {
  /* background-color: rgba(0, 0, 2, 0.5); color of hover */
  border-radius: 144px;
}

.main-map .mapboxgl-canvas {
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

.pw-input-map {
  border-radius: 8px;
}

.radarSelectBox {
  color: #81c7e1;
}
.radarSelectBox:hover {
  color: #6c9eb4;
}

.mapboxgl-popup-content {
  border-radius: 8px;
  /* display: 'flex';
    flex-direction: 'column';
    text-align: 'left'; */
  padding: 8;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  min-width: 160px;
  max-width: 250px;
}

.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip {
  border-top-color: rgba(0, 0, 0, 0.8);
}

.mapboxgl-popup-anchor-top .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip {
  border-bottom-color: rgba(0, 0, 0, 0.8);
}
