/* DARK MODE BACKGROUND */

.Background-Dark {
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  opacity: 1;
  z-index: -1;
  background: #121a24;
}

.Background-Dark-Group {
  z-index: 0;
  overflow: hidden;
  width: 100%;
  height: 574px;
  opacity: 0.4;
}

.Background-Dark-Gradient {
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #121a24 50%);
}

.Background-Dark-Scary-Gradient {
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, #cf2f266e 0%, rgba(31, 104, 51, 0) 60%);
}

.Background-Dark-Gradient-1 {
  position: absolute;
  height: 529px;
  width: 100%;
  left: 0%;
  right: 0%;
  top: 0px;
  background: linear-gradient(180deg, #022f87 0%, rgba(237, 244, 255, 0.32) 65.84%, rgba(0, 0, 0, 0) 100%);
}

.Background-Dark-Gradient-2 {
  position: absolute;
  height: 529px;
  width: 100%;
  left: 0%;
  right: 0%;
  top: 0px;
  background: linear-gradient(180deg, #11303d 0%, rgba(31, 104, 51, 0) 100%);
}

.Background-Dark-Circles-Group {
  position: absolute;
  overflow: hidden;
  width: 1238px;
  height: 1304.92px;
  right: -628px;
  top: -353.11px;
}

.Background-Dark-Circles-1 {
  position: absolute;
  height: 1304.92px;
  width: 1238px;
  border-radius: 50%;
  background: linear-gradient(149.87deg, #121a24 62.7%, #ffffff 80.21%);
  mix-blend-mode: overlay;
  opacity: 0.9;
  transform: rotate(-180deg);
  overflow: hidden;
}
.Background-Dark-Circles-2 {
  position: absolute;
  width: 880.88px;
  height: 928.5px;
  left: 93.64px;
  top: 351.132px;
  border-radius: 50%;
  background: linear-gradient(131.94deg, #121a24 63.52%, #ffffff 81.27%);
  mix-blend-mode: overlay;
  opacity: 0.9;
  transform: rotate(-180deg);
  overflow: hidden;
}

/* LIGHT MODE BACKGROUND */

.Background-Light {
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  opacity: 1;
  z-index: -1;
  background: #ffffff;
}

.Background-Light-Group {
  z-index: 0;
  overflow: hidden;
  width: 100%;
  height: 574px;
  opacity: 1;
}

.Background-Light-Gradient {
  z-index: 2;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, #fff 50%, rgba(255, 255, 255, 0) 100%);
}

.Background-Light-Scary-Gradient {
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, #f29c8a70 0%, rgba(31, 104, 51, 0) 100%);
}

.Background-Light-Gradient-1 {
  position: absolute;
  height: 529px;
  width: 100%;
  left: 0%;
  right: 0%;
  top: 0px;
  opacity: 0.3;
  background: linear-gradient(253.91deg, #11303d -10.4%, rgba(31, 104, 51, 0) 50.41%);
}

.Background-Light-Gradient-2 {
  position: absolute;
  height: 529px;
  width: 100%;
  left: 0%;
  right: 0%;
  top: 0px;
  /* background: linear-gradient(180deg, #022F87 0%, rgba(237, 244, 255, 0.32) 65.84%, rgba(0, 0, 0, 0) 100%); */
}

.Background-Light-Circles-Group {
  position: absolute;
  overflow: hidden;
  width: 1238px;
  height: 1304.92px;
  right: -628px;
  top: -353.11px;
}

.Background-Light-Circles-1 {
  position: absolute;
  height: 1304.92px;
  width: 1238px;
  border-radius: 50%;
  background: linear-gradient(149.87deg, rgba(255, 255, 255, 0) 62.7%, #ffffff 80.21%);
  mix-blend-mode: overlay;
  opacity: 0.9;
  transform: rotate(-180deg);
  overflow: hidden;
}
.Background-Light-Circles-2 {
  position: absolute;
  width: 880.88px;
  height: 928.5px;
  left: 93.64px;
  top: 351.132px;
  border-radius: 50%;
  background: linear-gradient(131.94deg, rgba(255, 255, 255, 0) 63.52%, #ffffff 81.27%);
  mix-blend-mode: overlay;
  opacity: 0.9;
  transform: rotate(-180deg);
  overflow: hidden;
}
