body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

hr {
  border: none;
  height: 1px;
  background-color: #35404f;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

*::-webkit-scrollbar {
  width: 0.4em;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
}

*::-webkit-scrollbar-thumb {
  background-color: lightgray;
  border-radius: 8px;
}

* {
  scrollbar-width: thin;
  scrollbar-color: lightgray transparent;
}

/* * {
  scrollbar-width: none;
 -ms-overflow-style: none;
}

*::-webkit-scrollbar {
 display: none;
} */
